<template>
  <div>
    <h1 class="title">
      спасибо<br>за покупку!
    </h1>

    <p class="title title--small title--small_wheel">
      поучаствуйте в беспроигрышной акции
    </p>

    <p class="txt txt__top">
      Выиграйте один из главных призов — IPhone 13, Macbook или $!
    </p>

    <p class="prize-run__txt prize-run__txt--top">
      {{ `${leftTimeObj.h}:${leftTimeObj.m}:${leftTimeObj.s}` }}
    </p>
    <div class="lottery">
      <div class="wheel__block">
        <div class="wheel-wrapper">
          <div class="wheel__bg">
            <div class="wheel__top" :style="{opacity: +!!prize}">
              <img src="../assets/images/wheel-pineapple/arrow.svg" alt="" class="wheel__top-img">
            </div>
            <div class="wheel__border"></div>
            <div class="wheel__center"></div>
            <div class="wheel" :style="stl"
              :class="{'wheel--transition' : spinning}"
              @transitionend="onWheelEnd" 
            >
              <div class="wheel__prize-block">
                <div class="wheel__prize" v-for="prize in shuffleArray" :key="prize.id">
                  <span class="wheel__prize-txt">
                    {{ prize.name }}
                  </span>
                </div>
              </div>
              <div class="wheel__center-bg"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text--center">
      <button class="button" :disabled="isDisabledButton" @click.prevent="getPrize">
        {{ loading ? 'Загрузка...' : 'Запустить' }}
      </button>
      <div class="lottery__left-presents">
        <p class="lottery__left-presents_attempts">
          Осталось попыток: {{ innerAvailableWheels }} из {{ productCount }}
        </p>
      </div>
    </div>
    <div>
      <div class="prize" v-if="prizeSuccess" ref="prize">
        <p class="text lottery__price">
          {{ prize.desc }}
        </p>
        <p class="prize-info__txt" v-if="!donation">
          {{ prize.desc2 }}
          <template v-if="prize.promocode">
            <br>
            <span  class="prize-info__promocode">
              {{ prize.promocode }}
            </span>
          </template>
        </p>
        <div class="prize-block" v-if="donation">
          <div class="prize-philanthropist">
            <p class="charity__tit">
              {{ !donated ? 'Хотите пожертвовать свой подарок на благотворительность?' : 'Спасибо!' }}
            </p>
            <div class="prize-philanthropist__block">
              <p class="prize-philanthropist__block-txt" v-if="!donated">
                Если Вы дадите свое согласие, подарок не будет отправлен Вам,
                а стоимость этого подарка - {{ donation }} рублей - мы перечислим
                в благотворительный фонд «Вера в Детство».
              </p>
              <p class="prize-philanthropist__block-txt" v-else>
                Вы выбрали пожертвовать свой подарок на благотворительность.
                Мы отправим от Вашего имени {{ donation }} рублей
                в Благотворительный фонд «Вера в Детство».
                О результатах сбора мы сообщим в нашем аккаунте @Letique.cosmetics
              </p>
              <div class="prize-philanthropist__buttons" v-if="!donated">

                <button class="button button--philanthropist" :disabled="donating" @click.prevent="selectDonation">
                  Да, я хочу участвовать
                </button>
                <button class="button button--philanthropist" :disabled="donating" @click.prevent="cancelDonation">
                  Нет, я выбираю подарок
                </button>
              </div>
            </div>
          </div>
          <button class="prize-philanthropist__close"  v-if="!donated" click.prevent="donation = 0"></button>
        </div>
      </div>
      <div class="link__lottery-bot">
        <a href="/rules.pdf" class="link link__lottery" target="_blank">
          подробные условия акции
        </a>
      </div>
    </div>
    <template v-if="prizeSuccess">
      <h2 class="title title--small title--marathon" ref="next">ХОТИТЕ ВЫИГРАТЬ ЕЩЕ?</h2>
      <p class="txt">Купите ещё один продукт и поучаствуйте в розыгрыше 🎁</p>
      <buy-block />
    </template>
    <prizes />
  </div>
</template>

<script>
import Prizes from './content/Prizes.vue'
import BuyBlock from '@/components/content/BuyBlock.vue'

export default {
  name: "Balloons",
  props: {
    number: String,
    phone: String,
    prizes: Array,
    leftTime: Number,
    availableWheels: Number,
    productCount: Number,
  },
  components: {
    Prizes,
    BuyBlock,
  },
  data() {
    return {
      prize: null,
      prizeSuccess: false,
      shuffleArray: [],
      leftTimeObj: {},
      innerAvailableWheels: 0,
      donation: null,
      donationId: null,
      donating: false,
      donated: null,
      orderPrizeId: null,
      loading: false,
      stl: {
        transform: `rotate(5790deg)`,
      },
      spinning: false,
    };
  },
  created() {
    this.shuffleArray = this.prizes.map((x) => x);
    if (this.shuffleArray.length < 12) {
      for (let i = this.shuffleArray.length; i < 12; i++) {
        this.shuffleArray.push({
          name: 'Средство Letique',
          id: -i,
        })
      }
    }
    this.shuffle(this.shuffleArray);
    this.innerAvailableWheels = this.availableWheels;
    this.setTimer();
  },
  methods: {
    async getPrize() {
      try {
        this.prize = null;
        this.prizeSuccess = false;
        this.donated = false;
        this.showDonation = true;
        this.loading = true;
        let response = await this.$axios.post(
          `${process.env.VUE_APP_URL}api/lv/lottery/${process.env.VUE_APP_ID}/prize`,
          {
            number: this.number,
            phone: this.phone,
          }
        );
        if (response.data.success === false) {
          this.$emit("errorPrize");
          this.loading = false;
        } else {
          this.setPrizeInfo(response);
        }
      } catch (e) {
        this.$emit("errorPrize");
      } finally {
        this.loading = false;
      }
    },
    setPrizeInfo(response) {
      this.prize = response.data.data.prize;
      this.orderPrizeId = response.data.data.id;
      this.donationId = setTimeout(() => {
        this.donation = null;
      }, 5 * 60 * 1000)
      try {
        let tempDonate = null;
        if (typeof response.data.data.data === 'string') {
          tempDonate = JSON.parse(response.data.data.data)
        } else {
          tempDonate = response.data.data.data;
        }
        this.donation = tempDonate.donate;
      } catch {
        this.donation = 0;
      }
      
      this.prize.add_name = this.getAddName(response.data.data.data)
      this.prize.promocode = null;
      
      this.$nextTick(() => {
        this.runWheel(response.data.data);
      });
    },
    runWheel(prize) {
      const prizeId = prize.prize_id;

      this.rnd = this.shuffleArray.findIndex((p) => p.id === prizeId);

      if (this.rnd === -1) {
        return;
      }
      this.spinning = true;

      const currentDeg = this.stl && this.stl.transform ? this.stl.transform.replace(/\D/g, '') : 0;
      let deg = +currentDeg < 1000 ? 5790 : 30;
      if (this.rnd === 0) {
        deg -= 30;
      } else {
        deg += ((this.rnd - 1) * 30);
      }

      this.innerAvailableWheels = prize.order.available_wheels;
      this.stl = {
        transform: `rotate(${-deg}deg)`,
      };
    },

    onWheelEnd() {
      this.spinning = false;
      this.prizeSuccess = true;
      setTimeout(() => {
        this.$refs.prize.scrollIntoView({behavior: "smooth"});
      }, 6000);
    },
    getAddName(d) {
      try {
        let data = JSON.parse(d);
        if (data && data.name) {
          return data.name;
        }
        return '';
      } catch {
        return '';
      }
    },
    shuffle(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },
    setTimer() {
      let leftTime = this.leftTime;
      this.leftTimeObj = this.secondsToTime(leftTime);
      let interval = setInterval(() => {
        leftTime--;
        this.leftTimeObj = this.secondsToTime(leftTime);
        if (leftTime <= 0) {
          clearInterval(interval);
          location.reload();
        }
      }, 1000)
    },
    secondsToTime(secs) {
      var hours = Math.floor(secs / (60 * 60));
      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60);
      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds);
      var obj = {
        h: hours < 10 ? ('0' + hours) : hours,
        m: minutes < 10 ? ('0' + minutes) : minutes,
        s: seconds < 10 ? ('0' + seconds) : seconds,
      };
      return obj;
    },
    updatePage() {
      if (this.innerAvailableWheels > 0) {
        location.reload();
      } else {
        this.$refs.next.scrollIntoView({behavior: "smooth"});
      }
      
    },
    async cancelDonation() {
      try {
        clearTimeout(this.donationId);
        this.donating = true;
        const response = await await this.$axios.post(`${process.env.VUE_APP_URL}api/lv/lottery/prize/cancel-donate`, {
          number: this.number,
          id: this.orderPrizeId,
        });
        if (response.data.success) {
          this.donation = 0;
          try {
            const extraData = JSON.parse(response.data.data.data);
            if (extraData.promocode) {
              this.prize.promocode = extraData.promocode;
            }
          } catch {
            // 
          }

        } else {
          alert('Ошибка');
        }
      } catch {
        alert('Ошибка');
      } finally {
        this.donating = false;
      }
    },
    async selectDonation() {
      clearTimeout(this.donationId);
      try {
        this.donating = true;
        const response = await await this.$axios.post(`${process.env.VUE_APP_URL}api/lv/lottery/prize/donate`, {
          number: this.number,
          id: this.orderPrizeId,
        });
        if (response.data.success) {
          this.donated = true;
        } else {
          alert('Ошибка');
        }
      } catch {
        alert('Ошибка');
      } finally {
        this.donating = false;
      }
    },
  },
  computed: {
    isDisabledButton() {
      return this.innerAvailableWheels <= 0 || this.loading
        || this.leftTime <= 0
        || this.spinning;
    },
  }
};
</script>

<style>

.text--center {
  text-align: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 4rem;
  height: 4rem;
  right: 0.8rem;
  top: -.8rem;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 4rem;
  height: 4rem;
  margin: .8rem;
  border: .8rem solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgba(255, 255, 255, .75) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.prize-run__txt--top {
    text-align: center!important;
    width: 100%!important;
    font-weight: 600 !important;
    font-size: 3rem !important;
    color: #222 !important;
    margin: -4rem 0 4rem;
}
@media (min-width: 768px) {
  .prize-run__txt--top {
    text-align: center;
    font-size: 5rem !important;
    color: #fff;
    margin: 0rem 0 0;
  }
}
body::after{
  position:absolute;
  width:0;
  height:0;
  overflow:hidden;
  z-index:-1;
  content: url("../assets/icons/click.gif") url("../assets/images/paper_white.png") url("../assets/images/paper.png");
}
.container--2 {
  overflow: visible;
}
.bg {
  background-position: top center;
  overflow: hidden;
  max-width: 100vw;
  min-height: 100vh;
}
.ball--prize .ball__house,
.ball--prize-pink .ball__house {
  opacity: 0;
}
.ball--prize::before {
  opacity: 0.3;
}

@media (max-width: 768px) {
  /*
  .ball--prize span:not(.ball__house){
    font-size: 2rem;
    top: 50%;
    left: 54%;
    transform: translate(-50%, -50%);
    width: 12rem;
    display: block;
  }
  */

  .balls {
    min-height: 39.4rem !important;
  }

}
.ball {
  /* animation: balloon1 6s ease-in-out infinite; */
  animation: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ball--2 {
  animation: balloon2 6s ease-in-out infinite;
}
.ball--3 {
  animation: balloon4 6s ease-in-out infinite;
}
.ball--4 {
  animation: balloon1 5s ease-in-out infinite;
}
.ball--5 {
  animation: balloon3 5s ease-in-out infinite;
}
.ball--6 {
  animation: balloon2 3s ease-in-out infinite;
}
.ball--7 {
  animation: balloon1 6s ease-in-out infinite;
}
.ball--8 {
  animation: balloon2 6s ease-in-out infinite;
}
.ball--9 {
  animation: balloon4 6s ease-in-out infinite;
}
.ball.ball--click,
.ball.ball--prize,
.ball.ball--prize-pink {
  animation: none !important;
}
@keyframes balloon1 {
  0%,
  100% {
    transform: translateY(0) rotate(-6deg);
  }
  50% {
    transform: translateY(-10px) rotate(2deg);
  }
}
@keyframes balloon2 {
  0%,
  100% {
    transform: translateY(0) rotate(6deg);
  }
  50% {
    transform: translateY(-10px) rotate(-2deg);
  }
}
@keyframes balloon3 {
  0%,
  100% {
    transform: translate(0, -10px) rotate(6eg);
  }
  50% {
    transform: translate(-10px, 10px) rotate(-2deg);
  }
}
@keyframes balloon4 {
  0%,
  100% {
    transform: translate(10px, -10px) rotate(-8eg);
  }
  50% {
    transform: translate(-15px, 10px) rotate(2deg);
  }
}
.prize-info__txt b {
  display: block;
  max-width: 250px;
  margin: 0 auto;
  text-align: center;
  background: #daf4ba;
  padding: 1rem 2rem;
  font-weight: 600;
  margin-top: 1rem;
}
@media (max-width: 768px) {
  .prize-info__txt b {
    max-width: 100%;
  }
}

html {
  transition: .3s ease background;
}
.dark .header__right{
      opacity: 0;
    visibility: hidden;
}
.dark .footer__logo img,
.dark .header__logo {
  filter: invert(1);
}
.dark .page__title,
.dark .footer__link,
.dark .footer__menu:before,
.dark .footer__big-link,
.dark .lk-name,
.dark .popup-lk a,
.dark .footer__grey-link {
  color: #fff;
}
.dark .show-menu:before,
.dark .show-menu:after,
.dark .show-menu span,
.dark .footer__menu:before {
  background: #fff;
}
.menu--opened.dark .show-menu:before,
.menu--opened.dark .show-menu:after,
.menu--opened.dark .show-menu span {
  background: #000;
}
.lottery--bg .page__title {
  z-index: 3;
  position: relative;
  color: #fff;
}
.dark .popup-button {
  border-top-color: #fff;
  border-left-color: #fff;
}
.dark .header__cart:after,
.dark .header__logo,
.dark .footer__logo,
.dark .menu__close  {
}
.lottery__text {
  font-size: 2.4rem;
  margin-bottom: 5rem;
  color: #fff;
}

.lottery--bg {
  background:#08BDA9 url("../assets/images/wheel/bg.jpg") no-repeat;
  background-size: cover;
  background-position: top 0 center;
  position: relative;
}
.safari.lottery--bg {
  background-position: top -13% center;
}
.lottery--bg .footer__grey-link,
.lottery--bg .footer__copy {
  color: #000;
}
@media (max-width: 750px) {

  .lottery__text {
    margin-bottom: 4rem;
  }
  .lottery--bg {
    background-image: url("../assets/images/wheel/bg.jpg");
    background-size: cover;
    background-position: top 0 center;
  }
  .safari.lottery--bg {
    background-position: top -15% center
  }
}

.lottery__title {
  font-weight: 900;
  text-align: center;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  font-size: 4.5rem;
  color: #00B8D4;
  margin: 7rem 0 4rem;
  line-height: 1.3;
}
.lottery--bg .agreenment-checkbox__text {
  color: #fff;
}
.bg--lottery .agreenment-checkbox span {
  border-color: #fff !important;
}
.link__lottery {
  color: #fff;
}
.page--lottery {
  position: static;
}

.lottery-opacity
.lottery-opacity-active, .lottery-opacity-active {
  transition: opacity .3s;
}
.lottery-opacity-enter {
  opacity: 0;
}
.color-btn--lottery {
  position: relative;
  z-index: 3;
  background: linear-gradient(93.8deg, #2B4AD9 -3.53%, #4EE9FF 105.91%) padding-box;
  font-size: 2.2rem;
  font-weight: bold;
  padding: 1.7rem 3.6rem 1.45rem;
  color: #fff;
}
.color-btn--lottery:hover {
  box-shadow: none;
}

.color-btn--lottery[disabled] {
  background: linear-gradient(93.8deg, #2B4AD9 -3.53%, #4EE9FF 105.91%) padding-box !important;
  cursor: not-allowed;
  opacity: 0.7;
}
.lottery__left-presents {
  color: #000;
  font-weight: 700;
  font-size: 1.6rem;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  margin-top: 2.7rem;
  text-align: center;
  font-family: Harmonia;
}
.lottery__left-presents_attempts {
  margin-bottom: 1rem;
}
.lottery__check .color-btn {
  margin-top: 4rem;
  color: #336063;
  border-color: #336063;
  padding-top: 1.6rem;
}
.lottery__check .color-btn:hover{
  box-shadow: none;
}
.lottery__text--top {
  font-size: 2rem;
}
@media (max-width: 750px) {
  .lottery__left-presents_attempts {
    max-width: 34rem;
    margin: 0 auto 1rem;
    line-height: 1.4;
  }

  .lottery__text--top {
    font-size: 2.4rem;
  }
  .color-btn {
    padding-top: 3.7vw;
  }
  .lottery__left-presents {
    font-size: 2.7rem;
    margin-top: 6rem;
  }
  .lottery--bg .agreenment-checkbox__text {
    font-size: 2rem;
  }
}
.code-instruction {
  max-width: 63rem;
  margin: 4rem auto;
  font-size: 1.6rem;
  line-height: 1.3;
  padding: 0 2rem;
}
.code-instruction p {
  margin-bottom: 1.3rem;
}
.code-instruction a {
  color: #00B8D4;
}

@media (max-width: 750px) {
  .code-instruction {
      max-width: 100%;
      margin: 4rem auto;
      font-size: 2.4rem;
      line-height: 1.3;
      padding: 0 2rem;
  }
}

.lottery--bg .agreenment-checkbox a {
  color: #fff;
}


.lottery--bg .agreenment-checkbox span:after {
  border-color: #fff;
}

.lottery--bg .link:after {
  background: #fff;
}

@media (max-width: 750px) {
  .lottery--bg .agreenment-checkbox {
    margin-left: 4rem;
    max-width: calc(100% - 4rem);
    margin-bottom: 2rem;
  }
}

.charity {
  background: #fff;
  width: 78rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 4rem 6rem;
  box-sizing: border-box;
  margin-top: 2.4rem;
}

.charity__tit {
  font-weight: 600;
  font-size: 2rem;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  color: #0B5A65;
}

.charity__txt {
  font-size: 1.6rem;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 0.04rem;
  color: #4F4F4F;
  margin: 1.6rem auto 4rem;
  max-width: 50.5rem;
}
.charity__btn {
  border: .2rem solid transparent;
  box-sizing: border-box;
  background: none;
  padding: 1.9rem 2.2rem 1.5rem;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border-image: linear-gradient(93.8deg, #2B4AD9 -3.53%, #4EE9FF 105.91%);
  border-image-slice: 1;
  background: #fff;
}

.charity__btn:hover,
.charity__btn--active {
  background: linear-gradient(93.8deg, #2B4AD9 -3.53%, #4EE9FF 105.91%) padding-box;
  color: #fff;
  border-image: none;
}
.charity__btns {
  margin: 0 -2rem;
}
.charity__btn {
  background: #fff;
  margin: 0 1rem;
}
.charity__btn:hover,
.charity__btn--active {
  background: linear-gradient(93.8deg, #2B4AD9 -3.53%, #4EE9FF 105.91%) padding-box;
}
.charity__btn + .charity__btn {
  background: #fff;
}
.charity__btn + .charity__btn:hover,
.charity__btn + .charity__btn--active {
  background: linear-gradient(93.8deg, #2B4AD9 -3.53%, #4EE9FF 105.91%) padding-box;
}
.charity__close {
  padding: 0;
  border: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  background: url("../assets/icons/close.svg") no-repeat;
  background-size: contain;
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}

@media (max-width: 750px) {
  .charity {
    width: 58rem;
    padding: 5.6rem 4.1rem 4rem;
  }

  .charity__btns {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .charity__tit {
    font-size: 2.6rem;
  }
  .charity__txt {
    font-size: 2.4rem;
    line-height: 1.6;
    margin: 1.6rem auto 3.2rem;
  }

  .charity__close {
    width: 2.4rem;
    height: 2.4rem;
    top: 2.4rem;
    right: 2.4rem;
  }

  .charity__btn {
    padding: 2rem 1.8rem 1.7rem;
    font-size: 2.2rem;
    letter-spacing: 0.09em;
    margin-bottom: 1.5rem;
  }
  .charity__btn + .charity__btn {
    margin-bottom: 0;
  }
}

.buttons__lottery {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4.4rem;
  margin: 4.4rem auto 3.6rem;
}

.button--border_lottery {
  text-decoration: none;
  padding: 2.15rem 3.6rem 1.65rem;
  transition: .3s all ease;
}

.button--border_lottery:hover {
  background: linear-gradient(93.8deg, #2B4AD9 -3.53%, #4EE9FF 105.91%) padding-box;
  border-color: rgba(0,0,0,0);
  color: #fff;
}

.buttons__lottery .button--border_lottery {
  margin-right: 3rem;
}
.wheel__error span{
  color: #f00;
  font-size: 1.8rem;
}
@media (max-width: 750px) {
  .buttons__lottery {
    flex-direction: column;
    margin: 6rem auto;
  }
  .buttons__lottery .button--border_lottery {
    margin: 0 0 2.4rem 0;
  }
  .color-btn--lottery {
    font-size: 2.7rem;
    padding: 1.7rem 3.6rem 1.45rem;
  }
  .wheel__error span{
    font-size: 2.2rem;
    margin-top: 1rem;
  }
}

</style>
