<template>
  <div>
    <div class="balls__blocks">
      <div class="balls__block">
        <div class="popup__error popup__error--2">
          <div class="popup__error-block">
            <span class="popup__error-icon popup__error-icon--x"></span>
            <div class="popup__error-links">
                <p class="popup__error-txt">Вы прокрутили все Колеса фортуны, доступные Вам.</p>
                <p class="popup__error-link">
                  В случае проблем напишите нам на
                  <a :href="'mailto:' + $root.supportEmail">{{$root.supportEmail}}</a>
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error2",
};
</script>
