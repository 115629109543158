<template>
  <div>
    <div class="balls__blocks">
      <div class="balls__block">
        <div class="balls" style="color:#000;font-size: 20px;justify-content: center">Загрузка...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style scoped></style>
