<template>
  <footer class="footer">
    <p class="footer__txt">
      техподдержка
      <a :href="'mailto:' + $root.supportEmail" class="footer__link">
        {{$root.supportEmail}}
      </a>
    </p>
    <a
      href="/rules.pdf?v=3"
      class="footer__link footer__txt footer__txt--privacy"
    >
      Правила проведения розыгрыша
    </a>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
