<template>
  <div>
      <div class="balls__blocks">
          <div class="balls__block">
              <div class="popup__error">
                <div class="popup__error-block">
                  <span class="popup__error-icon"></span>
                  <div class="popup__error-links">
                    <p class="popup__error-link">
                      Неправильная ссылка, напишите нам на
                      <a :href="'mailto:' + $root.supportEmail">
                        {{$root.supportEmail}}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
  export default {
    name: "Error"
  }
</script>