import Vue from "vue";
import App from "@/App.vue";
import axios from "axios";
import router from "@/router";

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
import "@/assets/scss/core.scss";
new Vue({
  router,
  render: (h) => h(App),
  computed: {
    supportEmail() {
      return process.env.VUE_APP_EMAIL;
    },
  }
}).$mount("#app");
