<template>
<div class="container container--2">
  <div class="prize-big__img" style="display: none;"></div>
  <div class="prizes" v-if="loaded" style="display: none;">
		<div class="prizes-top">
        <h4 class="prizes__title">
            участвуй и выигрывай:
        </h4>
    </div>
    <div class="prizes-blocks__wrapper">
      <div class="prizes-blocks">
        <div class="prizes-block prizes-block--cool" v-if="true || left[2808]">
            <div class="prizes-block__left">
              <div class="prizes-block__top">
                  <span class="prizes-block__top-x"></span>
                  <span class="prizes-block__top-qty">{{ left[2808] ? left[2808] : 0 }}</span>
              </div>
              <p class="prizes-block__name">Macbook<br>iPhone<br>$</p>
            </div>
            <img src="@/assets/images/summer/icecream.png" alt="img" class="prizes-block__img">
        </div>
        <div class="prizes-block prizes-block--cool" v-if="true || left[2808]">
            <div class="prizes-block__left">
              <div class="prizes-block__top">
                  <span class="prizes-block__top-x"></span>
                  <span class="prizes-block__top-qty">{{ left[2808] ? left[2808] : 0 }}</span>
              </div>
              <p class="prizes-block__name">Macbook<br>iPhone<br>$</p>
            </div>
            <img src="@/assets/images/summer/icecream.png" alt="img" class="prizes-block__img">
        </div>
        <div class="prizes-block prizes-block--bg2">
            <div class="prizes-block__top">
                <span class="prizes-block__top-x"></span>
                <span class="prizes-block__top-qty">4.000</span>
            </div>
            <p class="prizes-block__name">Скраб для тела SPARKLING CHAMPAGNE, 120 г</p>
        </div>
        <div class="prizes-block prizes-block--bg2">
            <div class="prizes-block__top">
                <span class="prizes-block__top-x"></span>
                <span class="prizes-block__top-qty">4.000</span>
            </div>
            <p class="prizes-block__name">Обертывание холодное для тела SEA SALT CARAMEL, 100 мл</p>
        </div>
        <div class="prizes-block prizes-block--bg2">
            <div class="prizes-block__top">
                <span class="prizes-block__top-x"></span>
                <span class="prizes-block__top-qty">5.000</span>
            </div>
            <p class="prizes-block__name">Гель для душа BLACK CURRANT, 100 мл</p>
        </div>
        <div class="prizes-block prizes-block--bg2">
            <div class="prizes-block__top">
                <span class="prizes-block__top-x"></span>
                <span class="prizes-block__top-qty">4.000</span>
            </div>
            <p class="prizes-block__name">Бомбочка для ванн WILD BERRIES</p>
        </div>
        <div class="prizes-block prizes-block--bg3">
            <div class="prizes-block__top">
                <span class="prizes-block__top-x"></span>
                <span class="prizes-block__top-qty">3.000</span>
            </div>
            <p class="prizes-block__name">Крем для рук BLACK CURRANT, 50 мл </p>
        </div>
        <div class="prizes-block prizes-block--bg3">
            <div class="prizes-block__top">
                <span class="prizes-block__top-x"></span>
                <span class="prizes-block__top-qty">100.000</span>
            </div>
            <p class="prizes-block__name">Книга рецептов «Шашлычный сезон»</p>
        </div>
        <div class="prizes-block prizes-block--bg3">
            <div class="prizes-block__top">
                <span class="prizes-block__top-x"></span>
                <span class="prizes-block__top-qty">100.000</span>
            </div>
            <p class="prizes-block__name">Фитнес-ежедневник</p>
        </div>
      </div>
    </div>
  </div>
  <div class="faq" id="faq">
      <h2 class="faq__tit">FAQ</h2>
      <div class="faq-blocks">
				<div class="faq-block">
						<h3 class="faq-block__title" @click.prevent="toggleFaq(1)">
						Как мне получить то, что выпало в Колесе фортуны?
						<span class="faq-block__title-icon" :class="{'faq-block__title-icon--active' : faq[1]}"></span>
						</h3>
						<div class="faq-block__active" :class="{'faq-block__active--db' : faq[1]}">
						<p class="faq-block__txt">
								Книга рецептов и Фитнес-ежедневник будут отправлены на электронную почту,
                указанную при покупке марафона. Для «Cредства LETIQUE» вам будет выдан промокод, который вы сможете применить на сайте letique.ru.
                При выигрыше главных призов связь с участником будет производиться по телефону, указанному при регистрации
                и заполнении анкеты, в рабочее время с 9:00 до 21:00.
						</p>
						</div>
				</div>
				<div class="faq-block" style="display: none;">
						<h3 class="faq-block__title" @click.prevent="toggleFaq(2)">
						Сколько раз Колесо Фортуны можно прокрутить?
						<span class="faq-block__title-icon" :class="{'faq-block__title-icon--active' : faq[2]}"></span>
						</h3>
						<div class="faq-block__active" :class="{'faq-block__active--db' : faq[2]}">
						<p class="faq-block__txt">
              В случае приобретения женского марафона Колесов фортуны можно прокрутить - 1 раз, мужского - 1 раз, марафона для пар с косметикой - 3 раза,
              марафона для пар без косметики - 2 раза, в случае продления или приобретения тренировок на пресс, массонабор или шпагат - 1 раз.
						</p>
						</div>
				</div>
				<div class="faq-block">
						<h3 class="faq-block__title" @click.prevent="toggleFaq(3)">
						Может ли повторяться то, что выпадает в Колесе фортуны? 
						<span class="faq-block__title-icon" :class="{'faq-block__title-icon--active' : faq[3]}"></span>
						</h3>
						<div class="faq-block__active" :class="{'faq-block__active--db' : faq[3]}">
						<p class="faq-block__txt">
								Да, в случае если вы прокрутите Колесо фортуны 2 и более раза повтор
								возможен.
						</p>
						</div>
				</div>
				<div class="faq-block">
						<h3 class="faq-block__title" @click.prevent="toggleFaq(4)">
						Что делать, если мне не пришла книга на почту?
						<span class="faq-block__title-icon" :class="{'faq-block__title-icon--active' : faq[4]}"></span>
						</h3>
						<div class="faq-block__active" :class="{'faq-block__active--db' : faq[4]}">
						<p class="faq-block__txt">
								Если вам не пришло письмо с книгой, в первую очередь мы рекомендуем
								проверить папку “Спам”. Если приз все же не пришел вам на
								почту, а также при возникновении других вопросов по розыгрышу
								“Колесо фортуны”, обращайтесь на почту
								<a style="color: #000" :href="'mailto:' + $root.supportEmail">{{$root.supportEmail}}</a>
						</p>
						</div>
				</div>
				<div class="faq-block">
						<h3 class="faq-block__title" @click.prevent="toggleFaq(5)">
						Можно ли заменить то, что выпало в Колесе фортуны?
						<span class="faq-block__title-icon" :class="{'faq-block__title-icon--active' : faq[5]}"></span>
						</h3>
						<div class="faq-block__active" :class="{'faq-block__active--db' : faq[5]}">
						<p class="faq-block__txt">
							Призы обмену не подлежат, денежный эквивалент приза не предоставляется.
						</p>
						</div>
				</div>
				<div class="faq-block ">
						<h3 class="faq-block__title"
							@click.prevent="toggleFaq(6)"
						>
						Куда обращаться, если у меня проблемы с Колесом фортуны?
						<span class="faq-block__title-icon" :class="{'faq-block__title-icon--active' : faq[6]}"></span>
						</h3>
						<div class="faq-block__active" :class="{'faq-block__active--db' : faq[6]}">
						<p class="faq-block__txt">
								Напишите нам на почту поддержки
								<a href="mailto:info@lerchek.fit" style="color:#000">{{$root.supportEmail}}</a> и мы решим Ваш
								вопрос!
						</p>
						</div>
				</div>
      </div>
  </div>
</div>
</template>
<script>
export default {
  data() {
    return {
      faq: {
        1 : false,
        2 : false,
        3 : false,
        4 : false,
        5 : false,
        6 : false,
        7 : false,
        8 : false,
      },
      loaded: false,
      left: {},
    }
  },
  methods: {
    toggleFaq(id) {
      this.faq[id] = !this.faq[id]
    },
    async loadPrizes() {
      try {
        let response = await this.$axios.get(
          `${process.env.VUE_APP_URL}api/lv/lottery/${process.env.VUE_APP_ID}/prizes-left`
        );
        if (response.data.success === false) {
          return;
        }
        response.data.data.forEach(d => {
            this.left[d.id] = d.left;
        })
        this.loaded = true;
      } catch (e) {
        return;
      }
    },
  },
  mounted() {
    this.loadPrizes();
  }
}
</script>